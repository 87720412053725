// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contacts-jsx": () => import("./../../../src/pages/contacts.jsx" /* webpackChunkName: "component---src-pages-contacts-jsx" */),
  "component---src-pages-events-jsx": () => import("./../../../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-galery-jsx": () => import("./../../../src/pages/galery.jsx" /* webpackChunkName: "component---src-pages-galery-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-news-detail-index-jsx": () => import("./../../../src/pages/news-detail/index.jsx" /* webpackChunkName: "component---src-pages-news-detail-index-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-nis-jsx": () => import("./../../../src/pages/nis.jsx" /* webpackChunkName: "component---src-pages-nis-jsx" */),
  "component---src-pages-partners-jsx": () => import("./../../../src/pages/partners.jsx" /* webpackChunkName: "component---src-pages-partners-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-research-jsx": () => import("./../../../src/pages/research.jsx" /* webpackChunkName: "component---src-pages-research-jsx" */),
  "component---src-pages-service-detail-index-jsx": () => import("./../../../src/pages/service-detail/index.jsx" /* webpackChunkName: "component---src-pages-service-detail-index-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-pages-team-member-index-jsx": () => import("./../../../src/pages/team-member/index.jsx" /* webpackChunkName: "component---src-pages-team-member-index-jsx" */),
  "component---src-pages-training-detail-index-jsx": () => import("./../../../src/pages/training-detail/index.jsx" /* webpackChunkName: "component---src-pages-training-detail-index-jsx" */),
  "component---src-pages-training-jsx": () => import("./../../../src/pages/training.jsx" /* webpackChunkName: "component---src-pages-training-jsx" */),
  "component---src-pages-training-list-jsx": () => import("./../../../src/pages/training-list.jsx" /* webpackChunkName: "component---src-pages-training-list-jsx" */)
}

