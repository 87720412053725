import React from 'react'
import { Link } from 'gatsby'
import axios from 'axios'

export default class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
    }
  }

  componentDidMount() {
    axios
      .get(`${process.env.API_URL}/umbraco/api/home/getbio`)
      .then(result => {
        this.setState({
          data: result.data,
        })
      })
      .catch(error => { })
  }

  render() {
    const { data } = this.state
    let bio = ''

    if (data) {
      bio = data.Bio
    }

    return (
      <footer className="footer-top section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="single_footer">
                <h4>Sobre Em Laços</h4>
                <div className="footer_contact">
                  <p>{bio}</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-xs-12">
              <div className="single_footer">
                <h4>Links</h4>
                <div className="footer_contact">
                  <ul>
                    <li>
                      <Link to="/">Início</Link>
                    </li>
                    <li>
                      <Link to="/about/">Sobre</Link>
                    </li>
                  {/*   <li>
                      <Link to="/services/">Consultas/Intervenções</Link>
                    </li>
                    <li>
                      <Link to="/training/">Formação</Link>
                    </li>
                    <li>
                      <Link to="/research/">Investigação</Link>
                    </li> */}
                    <li>
                      <Link to="/team/">Equipa</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-xs-12">
              <div className="single_footer spacer">
                <div className="footer_contact">
                  <ul>
                  <li>
                      <Link to="/events/">Eventos</Link>
                    </li>
                   {/*  <li>
                      <Link to="/galery/">Galeria</Link>
                    </li>
                    <li>
                      <Link to="/pricing/">Preçário</Link>
                    </li> */}
                    <li>
                      <Link to="/contacts/">Contactos</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="col-md-3 col-sm-3 col-xs-12">
              <div className="single_footer">
                <h4>Newsletter</h4>
                <p>
                  Subscribe to get the latest news, update and offer
                  information. Don't worry, we won't send spam!
                </p>
                <div className="signup">
                  <div id="mc_embed_signup">
                    <form
                      action="http://site90.us11.list-manage.com/subscribe/post?u=599a2153f4b86cb2f92d4af3a&amp;id=26d7c26287"
                      method="post"
                      id="mc-embedded-subscribe-form"
                      name="mc-embedded-subscribe-form"
                      className="validate"
                      target="_blank"
                      noValidate
                    >
                      <input
                        type="email"
                        name="EMAIL"
                        className="form-control"
                        id="mce-email"
                        placeholder="Email Address..."
                      />
                      <span className="input-group-btn">
                        <button
                          type="submit"
                          name="subscribe"
                          className="btn btn-default btn-newsletter-bg"
                        >
                          Subscribe!
                        </button>
                      </span>
                      <div id="mce-responses">
                        <div
                          className="response"
                          id="mce-error-response"
                          style={{ display: 'none' }}
                        />
                        <div
                          className="response"
                          id="mce-success-response"
                          style={{ display: 'none' }}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>*/}
          </div>
        </div>
      </footer>
    )
  }
}
