import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { FaAngleUp } from 'react-icons/fa';

import Header from './header'
import Footer from './footer'
import FooterBottom from './footerBottom'
import Transition from './Transition'
import ScrollUpButton from 'react-scroll-up-button';
import '../assets/bootstrap/css/bootstrap.min.css'
import '../assets/fonts/font-awesome.min.css'
import '../assets/css/animate.css'
import './layout.css'
import '../assets/css/responsive.css'

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Em Laços' },
            { name: 'keywords', content: 'clinica, saude, formação, psicologia, terapia' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title} />
        <Transition location={location}>{children}</Transition>
        <Footer />
        <FooterBottom />
        <ScrollUpButton ContainerClassName="topcontrol" TransitionClassName="return-to-top-transition">
          <FaAngleUp />
        </ScrollUpButton>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
