import React from 'react'
import { Link } from 'gatsby'
import axios from 'axios'

export default class FooterBottom extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
    }
  }

  componentDidMount() {}

  render() {
    return (
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12 wow zoomIn">
              <p className="footer_copyright">Em Laços &copy; 2019</p>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12 wow zoomIn">
               <div className="footer_social">
                <ul>
                  <li>
                    <a
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Facebook"
                      target="_blank"
                      href="https://www.facebook.com/Em-Laços-Equipa-de-Intervenção-Terapêutica-1502404389891378"
                    >
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Instagram"
                      target="_blank"
                      href="https://www.instagram.com/emlacos.pt/"
                    >
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                 <li>
                    <a
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Linkedin"
                      target="_blank"
                      href="https://www.linkedin.com/company/em-la%C3%A7os-equipa-de-interven%C3%A7%C3%A3o-terap%C3%AAutica/"
                    >
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}
