import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

export default class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      menuOpen: false,
    }
  }

  componentDidMount() {}

  render() {
    const { menuOpen } = this.state
    let menuStyle = menuOpen
      ? {
          display: 'block',
        }
      : {}

    return (
      <div>
        <div className="navbar navbar-default menu-top menu_dropdown">
          <div className="container">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle"
                onClick={() =>
                  this.setState({
                    menuOpen: !this.state.menuOpen,
                  })
                }
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              <StaticQuery
                query={graphql`
                  query {
                    mainlogo: file(
                      relativePath: { eq: "logo_negative_small.png" }
                    ) {
                      childImageSharp {
                        fixed(width: 83) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                `}
                render={data => (
                  <Link to="/" className="navbar-brand">
                    <Img
                      fixed={data.mainlogo.childImageSharp.fixed}
                      title="Em Laços"
                      alt="Em Laços"
                      className="header-responsive"
                    />
                  </Link>
                )}
              />
            </div>
            <div style={menuStyle} className="navbar-collapse collapse">
              <nav>
                <ul className="nav navbar-nav navbar-right">
                  <li>
                    <Link
                      to="/"
                      onClick={() =>
                        this.setState({
                          menuOpen: false,
                        })
                      }
                      className="page-scroll"
                    >
                      Início
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about/"
                      onClick={() =>
                        this.setState({
                          menuOpen: false,
                        })
                      }
                      className="page-scroll"
                    >
                      Sobre
                    </Link>
                  </li>
                  <li>
                    <a href="#" className="page-scroll">
                      Serviços
                    </a>
                    <ul className="sub-menu">
                      <li>
                        <Link
                          to="/services/"
                          onClick={() =>
                            this.setState({
                              menuOpen: false,
                            })
                          }
                          className="page-scroll"
                        >
                          Consultas
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/training/"
                          onClick={() =>
                            this.setState({
                              menuOpen: false,
                            })
                          }
                          className="page-scroll"
                        >
                         Formação
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/research/"
                          onClick={() =>
                            this.setState({
                              menuOpen: false,
                            })
                          }
                          className="page-scroll"
                        >
                         Investigação
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      to="/team/"
                      onClick={() =>
                        this.setState({
                          menuOpen: false,
                        })
                      }
                      className="page-scroll"
                    >
                      Equipa
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/nis/"
                      onClick={() =>
                        this.setState({
                          menuOpen: false,
                        })
                      }
                      className="page-scroll"
                    >
                      NIS
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/events/"
                      onClick={() =>
                        this.setState({
                          menuOpen: false,
                        })
                      }
                      className="page-scroll"
                    >
                      Eventos
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/news/"
                      onClick={() =>
                        this.setState({
                          menuOpen: false,
                        })
                      }
                      className="page-scroll"
                    >
                      Notícias
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/partners/"
                      onClick={() =>
                        this.setState({
                          menuOpen: false,
                        })
                      }
                      className="page-scroll"
                    >
                      Parceiros
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contacts/"
                      onClick={() =>
                        this.setState({
                          menuOpen: false,
                        })
                      }
                      className="page-scroll"
                    >
                      Contactos
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
